<template>
  <b-modal
    id="required-document-upload"
    v-model="showModal"
    title="Action Needed: Complete Your Documents"
    size="xl"
    @hidden="closeModal"
  >
    <p class="mb-4">
      We're almost there! To move forward with your {{ orderItem.product?.filing_name }} order, please complete and upload the required documents. Once these documents have been completed, we can proceed to the next step.
    </p>

    <b-table :items="documents" :fields="fields" responsive="sm" class="mb-3">
      <template #cell(title)="{ item }">
        {{ item.original_document.title }}
        <div v-if="item.note">
          <div class="small">Additional Information:</div>
          <div class="text-muted small table-column-note">
            <div class="wrap-text">{{ item.note }}</div>
          </div>
        </div>
      </template>

      <template #cell(created_at)="{ item }">
        {{ formatDate(item) }}
      </template>

      <template #cell(actions)="{ item }">
        <b-button
          variant="primary"
          :href="item.original_document.url"
          target="_blank"
          class="mr-2"
        >
          Download
        </b-button>

        <b-button
          v-if="item.new_document"
          variant="info"
          :href="item.new_document.url"
          target="_blank"
        >
          View Uploaded Document
        </b-button>

        <template v-else>
          <b-button
            variant="success"
            :disabled="uploading[item.original_document.id]"
            @click="triggerFileInput(item)"
          >
            <b-spinner v-if="uploading[item.original_document.id]" small />
            {{ uploading[item.original_document.id] ? 'Uploading...' : 'Upload' }}
          </b-button>

          <input
            :ref="el => setFileInputRef(el, item.original_document.id)"
            type="file"
            class="d-none"
            accept="application/pdf"
            @change="handleFileUpload($event, item)"
          >
        </template>
      </template>
    </b-table>

    <template #modal-footer>
      <b-button variant="secondary" @click="$bvModal.hide('required-document-upload')">Close</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { formatStandardDateFromIso } from '@/common/modules/formatters'
import http from '@/http'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'RequiredDocumentUpload',
  mixins: [makeToastMixin],
  data() {
    return {
      showModal: false,
      orderItemId: null,
      orderItem: {},
      documents: [],
      fileInputs: {},
      uploading: {},
      fields: [
        { key: 'title', label: 'Title' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.showModal = to.name === 'requiredDocumentUpload'
        if (this.showModal) {
          this.orderItemId = this.$route.params.orderItemId
          this.getDocuments()
        }
      },
    },
  },
  methods: {
    formatStandardDateFromIso,
    ...mapActions('orderItems', ['getRequestedDocuments']),
    closeModal() {
      this.$emit('closeVehicleOrderModal')
    },
    async getDocuments () {
      if(!this.orderItemId) return
      const response = await this.getRequestedDocuments(this.orderItemId)
      this.orderItem = response?.order_item || {}
      this.documents = response?.documents || []
    },
    formatDate(document) {
      return formatStandardDateFromIso(document.original_document.created_at * 1000) || ''
    },
    setFileInputRef(el, docId) {
      if (el) {
        this.$set(this.fileInputs, docId, el)
      }
    },
    triggerFileInput(item) {
      if (this.fileInputs[item.original_document.id]) {
        this.fileInputs[item.original_document.id].click()
      }
    },
    async handleFileUpload(event, item) {
      const file = event.target.files[0]
      if (!file) return

      const docId = item.original_document.id
      this.$set(this.uploading, docId, true)

      try {
        const formData = new FormData()
        formData.append('files[]', file)
        formData.append('source', 'vehicles')
        formData.append('type', 'Initial Vehicle Documentation')
        formData.append('company_id', item.original_document.company_id)
        formData.append('jurisdiction_id', item.original_document.jurisdiction_id)
        formData.append('original_doc_id', docId)

        await http.post('client/client_documents/client_upload_requested_doc', formData)
        await this.getDocuments()

        // Reset state
        this.$set(this.uploading, docId, false)

        if (this.fileInputs[docId]) {
          this.fileInputs[docId].value = ""
        }
      } catch (error) {
        this.errorToast('Upload failed', error.message)
        this.$set(this.uploading, docId, false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap-text {
  white-space: pre-line;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-column-note {
  max-width: 300px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
