var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "required-document-upload",
        title: "Action Needed: Complete Your Documents",
        size: "xl",
      },
      on: { hidden: _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("required-document-upload")
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          "\n    We're almost there! To move forward with your " +
            _vm._s(_vm.orderItem.product?.filing_name) +
            " order, please complete and upload the required documents. Once these documents have been completed, we can proceed to the next step.\n  "
        ),
      ]),
      _c("b-table", {
        staticClass: "mb-3",
        attrs: { items: _vm.documents, fields: _vm.fields, responsive: "sm" },
        scopedSlots: _vm._u([
          {
            key: "cell(title)",
            fn: function ({ item }) {
              return [
                _vm._v(
                  "\n      " + _vm._s(item.original_document.title) + "\n      "
                ),
                item.note
                  ? _c("div", [
                      _c("div", { staticClass: "small" }, [
                        _vm._v("Additional Information:"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-muted small table-column-note" },
                        [
                          _c("div", { staticClass: "wrap-text" }, [
                            _vm._v(_vm._s(item.note)),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(created_at)",
            fn: function ({ item }) {
              return [
                _vm._v("\n      " + _vm._s(_vm.formatDate(item)) + "\n    "),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function ({ item }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      variant: "primary",
                      href: item.original_document.url,
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n        Download\n      ")]
                ),
                item.new_document
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "info",
                          href: item.new_document.url,
                          target: "_blank",
                        },
                      },
                      [_vm._v("\n        View Uploaded Document\n      ")]
                    )
                  : [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "success",
                            disabled: _vm.uploading[item.original_document.id],
                          },
                          on: {
                            click: function ($event) {
                              return _vm.triggerFileInput(item)
                            },
                          },
                        },
                        [
                          _vm.uploading[item.original_document.id]
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.uploading[item.original_document.id]
                                  ? "Uploading..."
                                  : "Upload"
                              ) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                      _c("input", {
                        ref: (el) =>
                          _vm.setFileInputRef(el, item.original_document.id),
                        staticClass: "d-none",
                        attrs: { type: "file", accept: "application/pdf" },
                        on: {
                          change: function ($event) {
                            return _vm.handleFileUpload($event, item)
                          },
                        },
                      }),
                    ],
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }